import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '_utils/token'
import downloadUrl from '_utils/downloadUrl'

const RegisterLoading = () => import('_views/RegisterLoading')
const Register = () => {
  if (process.env.VUE_APP_REGISTER_PATH) {
    return import(process.env.VUE_APP_REGISTER_PATH)
  } else {
    return import('_views/Register')
  }
}
const LoanInfo = () => import('_views/LoanInfo')
const ApiProductMiddlePage = () => import('_views/ApiProductMiddlePage')
const SuperLoan = () => import('_views/SuperLoan')
const Examining = () => import('_views/Examining')
const Special = () => import('_views/Special')
const MiddlePage = () => import('_views/MiddlePage')
const Fail = () => import('_views/Fail')
const InReview = () => import('_views/InReview')
const Success = () => import('_views/Success')
const WeChatCode = () => import('_views/WeChatCode')
const ApplyHistory = () => import('_views/ApplyHistory')
const ClearApplyHistory = () => import('_views/ClearApplyHistory')
const CreditReport = () => import('_views/CreditReport')
const OneClickLogin = () => import('_views/OneClickLogin')
const MatchBigLoanProduct = () => import('_views/MatchBigLoanProduct')

Vue.use(VueRouter)
const routes = [
  {
  path: '/',
  redirect: process.env.VUE_APP_NO_CACHE_AND_NO_REGISTER_LOADING ? 'register' :  '/registerLoading'
  },
  {
    path: '/registerLoading',
    component: RegisterLoading
  },
  {
    path: '/apiProductMiddlePage',
    component: ApiProductMiddlePage
  },
  {
    path: '/loanInfo',
    component: LoanInfo
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/superLoan',
    component: SuperLoan,
    name: 'superLoan'
  },
  {
    path: '/middlepage',
    component: MiddlePage
  },
  {
    path: '/examining',
    component: Examining
  },
  {
    path: '/special',
    component: Special
  },
  {
    path: '/fail',
    component: Fail
  },
  {
    path: '/inReview',
    component: InReview
  },
  {
    path: '/success',
    component: Success
  },
  {
    path: '/weChatCode',
    component: WeChatCode
  },
  {
    path: '/applyHistory',
    component: ApplyHistory
  },
  {
    path: '/clearApplyHistory',
    component: ClearApplyHistory
  },
  {
    path: '/creditReport',
    component: CreditReport
  },
  {
    path: '/oneClickLogin',
    component: OneClickLogin
  },
  {
    path: '/matchBigLoanProduct',
    component: MatchBigLoanProduct
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/register' && to.path !== '/registerLoading' && !getToken()) {
    next('/registerLoading')
  } else if (to.path === '/superLoan') {
    // 这里是处理部分渠道不跳转贷超的逻辑
    if (process.env.VUE_APP_FAILED_TO_DOWNLOAD) {
      // 固定跳下载
      localStorage.setItem('toDownload', '1')

      // 特殊渠道跳转不带token
      const token = process.env.VUE_APP_NO_TOKEN_TO_DOWNLOAD ? '' : getToken()
      location.replace(`${downloadUrl}?token=${token}`)
    } else if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE) {
      // 固定跳失败页
      next('/fail')
    } else if (process.env.VUE_APP_TO_IN_REVIEW_AFTER_SUBMIT) {
      // 固定跳审核中页面
      next('/inReview')
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
